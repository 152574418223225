import React, { Fragment, useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import Header from "./components/headerFooter/Header";
import Footer from "./components/headerFooter/Footer";
import Userlogin from "./pages/Login";
import Userregister from "./pages/Register";
import Resetpwd from "./pages/Resetpwd";
import Property from "./pages/Property";
import PropertyListing from "./pages/PropertyListing";
import VerifyOtp from "./pages/VerifyOtp";
import VerifyOtpPassword from "./pages/VerifyOtpPassword";
import useThemeStore from "./store/themeStore";
import { getTheme, ThemeProps } from "./utils/theme";
import WhiteLabel from "./pages/WhiteLabel";
import Blog from "./pages/Blog";
import ScrollToTop from "./ScrollToTop";
import ServicesPage from "./pages/Services";
import PropertyValuation from "./pages/PropertyValuation";
import "./App.css"; // Your CSS file
import useTokenStore from "./store/store";

// New component to conditionally render Footer
const ConditionalFooter = () => {
  const location = useLocation();
  const noFooterRoutes = ['/login', '/register', '/resetPassword', '/verifyPasswordotp', '/verifyotp'];

  if (noFooterRoutes.includes(location.pathname)) {
    return null;
  }

  return <Footer />;
};

// PrivateRoute component to guard routes
const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const location = useLocation();
  const getToken = useTokenStore((state) => state.token);
  console.log("PrivateRoute: Checking authentication for path:", location.pathname);

  if (!getToken) {
    const intendedUrl = location.pathname + location.search;
    console.log("PrivateRoute: User not authenticated. Redirecting to login with intended URL:", intendedUrl);
    return (
      <Navigate
        to={`/login?redirectTo=${encodeURIComponent(intendedUrl)}`}
        replace
      />
    );
  }

  console.log("PrivateRoute: User is authenticated. Rendering component.");
  return element;
};

function App() {
  const setTheme = useThemeStore((state) => state.setTheme);
  const [loading, setLoading] = useState(true);
  const currentTheme = useThemeStore.getState().theme;

  // Load theme from localStorage or fetch it
  const loadSavedTheme = useCallback(() => {
    try {
      const savedTheme = localStorage.getItem('whitelabel_theme');
      if (savedTheme) {
        const parsedTheme = JSON.parse(savedTheme);
        setTheme(parsedTheme);
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error('Error loading saved theme:', error);
    }
    return false;
  }, [setTheme]);

  const fetchTheme = useCallback(async () => {
    // Only fetch if there's no saved theme
    if (!loadSavedTheme()) {
      const response = await getTheme();
      if (response) {
        setTheme(response);
        setLoading(false);
      }
    }
  }, [setTheme, loadSavedTheme]);

  // Initial theme loading
  useEffect(() => {
    fetchTheme();
  }, [fetchTheme]);

  // Handle incoming theme updates from Nuxt
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const allowedOrigins = ["http://localhost:3000", "http://localhost:3001"];
      if (!allowedOrigins.includes(event.origin)) return;

      try {
        const { type, theme } = event.data;
        if (type === "THEME_UPDATE" && theme) {
          console.log("Received theme update from parent:", theme);
          setTheme(theme as ThemeProps);
          setLoading(false);
          
          // Save to localStorage when receiving updates from Nuxt
          localStorage.setItem('whitelabel_theme', JSON.stringify(theme));
        }
      } catch (error) {
        console.error("Error processing message:", error);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [setTheme]);

  // Send color updates to Nuxt parent
  useEffect(() => {
    if (currentTheme?.color1 || currentTheme?.color2 || currentTheme?.txtColor) {
      // Save theme changes to localStorage
      localStorage.setItem('whitelabel_theme', JSON.stringify(currentTheme));
      
      // Send update to parent
      window.parent.postMessage({
        type: 'COLOR_UPDATE',
        colors: {
          color1: currentTheme.color1,
          color2: currentTheme.color2,
          txtColor: currentTheme.txtColor
        }
      }, 'http://localhost:3000'); // Your Nuxt origin
    }
  }, [currentTheme?.color1, currentTheme?.color2, currentTheme?.txtColor]);

  const getThemes = useThemeStore(
    (state) => state.theme as unknown as ThemeProps
  );

  return (
    <div className="App">
      {!loading && (
        <Router basename={"/"}>
          <ScrollToTop>
            <Fragment>
              <Header />
              <Routes>
                <Route
                  path="/"
                  element={
                    getThemes && getThemes.hybrid ? (
                      <PropertyListing />
                    ) : (
                      <WhiteLabel />
                    )
                  }
                />
                {/* Public Routes */}
                <Route path="/login" element={<Userlogin />} />
                <Route path="/register" element={<Userregister />} />
                <Route path="/resetPassword" element={<Resetpwd />} />
                <Route path="/verifyPasswordOtp" element={<VerifyOtpPassword />} />
                <Route path="/verifyotp" element={<VerifyOtp />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/propertyvaluation" element={<PropertyValuation />} />
                <Route path="/listing" element={<PropertyListing />} />
                {/* Private Routes */}
                <Route path="/listing/:id" element={<PrivateRoute element={<Property />} />} />
                <Route path="/listing/:id/failure" element={<PrivateRoute element={<Property />} />} />
                <Route path="/listing/:id/success" element={<PrivateRoute element={<Property />} />} />
              </Routes>
              <ConditionalFooter />
            </Fragment>
          </ScrollToTop>
        </Router>
      )}
      {loading && (
        <div className="flex justify-center items-center h-screen">Loading...</div>
      )}
    </div>
  );
}

export default App;